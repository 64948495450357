import { EXPERIMENT, isVariationActive } from 'lib/ablyft';
import { questions } from 'qualification/schema/questions';
import { createStep } from 'qualification/schema/questions/utils';

export const STEP_IDS = {
  city: 'city',
  cateringCategories: 'catering_categories',
  eventDate: 'event_date',
  dietaryRestrictions: 'dietary_restrictions',
  budgetAndPeopleCount: 'people_count_and_budget',
  rareCustomerTier: 'rare_customer_tier',
  customerTier: 'customer_tier',
} as const;

export const cityStep = createStep({
  id: STEP_IDS.city,
  questions: [questions.city],
  content: {
    titleEn: 'Select your City',
    titleDe: 'Wähle deine Stadt',
  },
});

export const cateringCategoryStep = createStep({
  id: STEP_IDS.cateringCategories,
  questions: [questions.cateringCategories],
  content: {
    titleEn: 'What type of Catering do you need?',
    titleDe: 'Welche Art von Catering benötigst du?',
    subtitleEn: 'Multiple selection possible',
    subtitleDe: 'Mehrfachauswahl möglich',
  },
});

export const eventDateAndLeadTypeStep = createStep({
  id: STEP_IDS.eventDate,
  questions: [
    questions.date,
    questions.isRecurring,
    questions.leadType,
    questions.isMultipleDays,
  ],
  content: {
    titleEn: 'When will your event take place?',
    titleDe: 'Wann findet dein Event statt?',
    assistiveTextEn: 'When is your event?',
    assistiveTextDe: 'Wann ist dein Event?',
  },
});

export const dietaryRestrictionStep = createStep({
  id: STEP_IDS.dietaryRestrictions,
  questions: [
    questions.numberOfVegetarians,
    questions.numberOfVegans,
    questions.numberOfCarnivore,
  ],
  content: {
    titleEn: 'What should be the distribution of the diets?',
    titleDe: 'Wie soll die Verteilung der Ernährungsformen sein?',
    subtitleEn:
      'Based on our catering experience, 50% vegetarian, 30% meat and 20% vegan is an optimal distribution. You can adjust these values now or later.',
    subtitleDe:
      'Basierend auf unserer Catering Erfahrung sind 50% vegetarisch, 30% Fleisch und 20% vegan eine optimale Verteilung. Du kannst diese Werte jetzt oder auch später anpassen.',
    mobileSubtitleEn:
      'Based on our experience, this is an ideal distribution, you can adjust these values now or later.',
    mobileSubtitleDe:
      'Basierend aus unserer Erfahrung ist dies eine ideale Verteilung, du kannst diese Werte jetzt oder auch später anpassen.',
  },
});

export const customerTierStep = createStep({
  id: STEP_IDS.customerTier,
  questions: [questions.customerTier],
  content: {
    titleEn: 'heycater! specializes in customers with regular catering needs',
    titleDe:
      'heycater! ist spezialisiert auf Kunden mit regelmäßigem Cateringbedarf',
  },
});

export const rareCustomerTierStep = createStep({
  id: STEP_IDS.rareCustomerTier,
  questions: [questions.rareCustomerTierQuestion],
  content: {
    titleEn: isVariationActive(EXPERIMENT.customerTierABC.variations.variationB)
      ? "You don't have regular needs? No problem!"
      : '',
    titleDe: isVariationActive(EXPERIMENT.customerTierABC.variations.variationB)
      ? 'Du hast keinen regelmäßigen Bedarf? Kein Problem!'
      : '',
    footNote: 'qualification:rareCustomerTier.footNote',
  },
});

export const budgetAndPeopleCountStep = createStep({
  id: STEP_IDS.budgetAndPeopleCount,
  questions: [
    questions.peopleCount,
    questions.pricePerPerson,
    questions.totalBudget,
  ],
  content: {
    titleEn: 'How many people are to be catered for and what is your budget?',
    titleDe:
      'Wie viele Personen sollen ca. verköstigt werden und was hast du für ein Budget?',
  },
});

export const steps = {
  city: cityStep,
  cateringCategories: cateringCategoryStep,
  eventDateAndLeadType: eventDateAndLeadTypeStep,
  dietaryRestriction: dietaryRestrictionStep,
  budgetAndPeopleCount: budgetAndPeopleCountStep,
  customerTier: customerTierStep,
  rareCustomerTierStep: rareCustomerTierStep,
};

export type StepId = typeof STEP_IDS[keyof typeof STEP_IDS];

/**
 * A record of steps, where their id is the key
 */
export const SCHEMA_STEPS = createStepSchema({
  [STEP_IDS.cateringCategories]: cateringCategoryStep,
  [STEP_IDS.city]: cityStep,
  [STEP_IDS.eventDate]: eventDateAndLeadTypeStep,
  [STEP_IDS.dietaryRestrictions]: dietaryRestrictionStep,
  [STEP_IDS.budgetAndPeopleCount]: budgetAndPeopleCountStep,
  [STEP_IDS.customerTier]: customerTierStep,
  [STEP_IDS.rareCustomerTier]: rareCustomerTierStep,
});

function createStepSchema<T extends Record<StepId, unknown>>(obj: T) {
  return obj;
}
