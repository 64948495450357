import { ValidationError } from 'yup';

import {
  ABLyft,
  CUSTOM_ABLYFT_EVENT,
  EXPERIMENT,
  isVariationActive,
} from 'lib/ablyft';
import { AnsweringQualificationState } from 'qualification/context/model';
import { getAnswer } from 'qualification/context/reducer/utils';
import { Question, QuestionId } from 'qualification/schema/model';
import { questions, SCHEMA_QUESTIONS } from 'qualification/schema/questions';
import {
  pricePerPersonQuestion,
  totalBudgetQuestion,
} from 'qualification/schema/questions/budgetQuestion';
import { cateringCategoriesQuestion } from 'qualification/schema/questions/cateringCategoriesQuestion';
import { DEFAULT_DIETARY_PERCENTAGE } from 'qualification/schema/questions/dietaryRestrictionsQuestion';

interface AnswerPayload {
  id: QuestionId;
  value: unknown;
  options?: AnswerQuestionOptions;
}

interface AnswerQuestionOptions {
  /**
   * Controls if the answer gets stored in the state, in case it's invalid
   */
  storeIfInvalid?: boolean;
  isSideEffect?: boolean;
  isManagedAccount?: boolean;
}

const DEFAULT_OPTIONS: AnswerQuestionOptions = {
  storeIfInvalid: true,
};

export function answerQuestion(
  state: AnsweringQualificationState,
  answer: AnswerPayload,
  options: AnswerQuestionOptions = {}
) {
  const question = SCHEMA_QUESTIONS[answer.id];

  if (!question || !state.qualification.answers) {
    return state;
  }

  const { storeIfInvalid, isSideEffect } = { ...DEFAULT_OPTIONS, ...options };

  let payload: typeof answer.value | typeof answer.value[] = answer.value;
  const answerOptions: typeof answer.options = answer.options || {
    isManagedAccount: false,
  };
  const { isManagedAccount } = answerOptions;

  if (
    question.getValidator({
      answers: state.qualification.answers,
      isManagedAccount: isManagedAccount,
    }).type === 'array' &&
    !Array.isArray(answer.value)
  ) {
    const prevAnswer = getAnswer(state, question.id);

    if (prevAnswer === undefined) {
      payload = [answer.value];
    } else if (
      Array.isArray(prevAnswer) &&
      prevAnswer.includes(answer.value as any)
    ) {
      payload = prevAnswer.filter((_answer) => _answer !== payload);
    } else if (Array.isArray(prevAnswer)) {
      payload = [...prevAnswer, answer.value];
    }
  }

  const errors = validateAnswer(question, payload, state, {
    isManagedAccount: isManagedAccount,
  });
  const isInvalid = Boolean(errors && errors.length > 0);

  if (isInvalid && !storeIfInvalid) {
    return state;
  }

  let newState = {
    ...state,
    qualification: {
      ...state.qualification,
      answers: {
        ...state.qualification.answers,
        [answer.id]: payload,
      },
      errors: { ...state.qualification.errors, [answer.id]: errors },
    },
  };

  // Handle Side effects
  if (!isSideEffect) {
    if (!newState._firstAnsweredBudgetField) {
      if (answer.id === questions.peopleCount.id) {
        newState._firstAnsweredBudgetField = 'peopleCount';
      }
      if (answer.id === questions.totalBudget.id) {
        newState._firstAnsweredBudgetField = 'totalBudget';
      }
    }
    if (answer.id === questions.peopleCount.id) {
      newState._isPeopleCountTouched = true;
    }
    newState = handlePeopleCountChange(newState, answer);
    newState = handleDietaryRestrictionsChange(newState, answer);
    newState = handlePricePerPersonChange(newState, answer);
    newState = handleTotalBudgetChange(newState, answer, {
      isManagedAccount: isManagedAccount,
    });

    if (answer.id === cateringCategoriesQuestion.id) {
      newState = handleCateringCategoryChange(newState);
    }
  }

  if (
    isVariationActive(EXPERIMENT.homePage.variations.original) ||
    isVariationActive(EXPERIMENT.homePage.variations.variationA) ||
    isVariationActive(EXPERIMENT.homePage.variations.variationB)
  ) {
    ABLyft.trackCustomEvent(CUSTOM_ABLYFT_EVENT.qfEngagement);
  }
  return newState;
}

interface ValidateAnswerOptions {
  isManagedAccount?: boolean;
}

function validateAnswer<T extends Question>(
  question: T,
  answer: unknown,
  state: AnsweringQualificationState,
  options: ValidateAnswerOptions = {}
) {
  if (!question) {
    return null;
  }
  try {
    if ('getValidator' in question) {
      question
        .getValidator({
          answers: state.qualification.answers,
          selectedCaterer: state.qualification.selectedCaterer,
          isManagedAccount: options.isManagedAccount,
        })
        ?.validateSync(answer, {
          context: state.qualification.answers,
        });
    }
  } catch (e) {
    if (e instanceof ValidationError) {
      return e.errors;
    }
  }
  return null;
}

function handleCateringCategoryChange(state: AnsweringQualificationState) {
  const pricePerQuestionAnswer =
    state.qualification.answers[pricePerPersonQuestion.id];
  if (pricePerQuestionAnswer !== undefined) {
    const newState = answerQuestion(
      state,
      {
        id: pricePerPersonQuestion.id,
        value: state.qualification.answers[pricePerPersonQuestion.id],
      },
      { isSideEffect: true }
    );
    return newState;
  }
  return state;
}

function handleDietaryRestrictionsChange(
  state: AnsweringQualificationState,
  answer: {
    id: Exclude<QuestionId, 'budget' | 'dietary_restrictions'>;
    value: unknown;
  }
) {
  const currentStep = state.qualification.step;
  if (currentStep?.id !== 'dietary_restrictions') {
    return state;
  }
  if (typeof answer.value !== 'number') {
    return state;
  }

  let newState = { ...state };

  if (
    [
      'number_of_carnivore',
      'number_of_vegetarians',
      'number_of_vegans',
    ].includes(answer.id) &&
    state.qualification.step?.id === 'dietary_restrictions'
  ) {
    const [carnivoreQuestion, vegansQuestion, vegetariansQuestion] = [
      SCHEMA_QUESTIONS[questions.numberOfCarnivore.id],
      SCHEMA_QUESTIONS[questions.numberOfVegans.id],
      SCHEMA_QUESTIONS[questions.numberOfVegetarians.id],
    ];
    if (carnivoreQuestion && vegetariansQuestion && vegansQuestion) {
      const [carnivoreCount, vegetarianCount, veganCount] = [
        answer.id === 'number_of_carnivore'
          ? answer.value
          : getAnswer(state, carnivoreQuestion.id) || 0,
        answer.id === 'number_of_vegetarians'
          ? answer.value
          : getAnswer(state, vegetariansQuestion.id) || 0,
        answer.id === 'number_of_vegans'
          ? answer.value
          : getAnswer(state, vegansQuestion.id) || 0,
      ];

      const peopleCount = carnivoreCount + vegetarianCount + veganCount;
      if (peopleCount !== state.qualification.answers.min_order_number) {
        newState = answerQuestion(
          state,
          {
            id: 'min_order_number',
            value: peopleCount,
          },
          { isSideEffect: true }
        );
      }
    }
  }
  return newState;
}

function handlePeopleCountChange(
  state: AnsweringQualificationState,
  answer: AnswerPayload
) {
  const currentQuestion = state.qualification.step;
  const peopleCount = answer.value;
  let newState = { ...state };

  if (
    answer.id !== questions.peopleCount.id ||
    typeof peopleCount !== 'number'
  ) {
    return state;
  }
  const totalBudget = state.qualification.answers[totalBudgetQuestion.id];
  const pricePerPerson = state.qualification.answers[pricePerPersonQuestion.id];

  if (state._firstAnsweredBudgetField === 'totalBudget' && totalBudget) {
    // update the price per person
    const calculatedPricePerPerson =
      Math.round((totalBudget / peopleCount) * 100) / 100;
    if (calculatedPricePerPerson !== pricePerPerson) {
      newState = answerQuestion(
        newState,
        {
          id: pricePerPersonQuestion.id,
          value: calculatedPricePerPerson,
        },
        { isSideEffect: true }
      );
    }
  }

  // Update total budget
  if (state._firstAnsweredBudgetField === 'peopleCount') {
    const pricePerPerson = state.qualification.answers.price_per_person;
    if (pricePerPerson) {
      const updatedOrderAmount = pricePerPerson * peopleCount;

      if (totalBudget !== updatedOrderAmount) {
        newState = answerQuestion(
          newState,
          {
            id: 'caterer_minimum_order_value',
            value: updatedOrderAmount,
          },
          { isSideEffect: true }
        );
      }
    }
  }

  // Update dietary distribution
  if (currentQuestion?.id !== 'dietary_restrictions') {
    const carnivoreCount = Math.round(
      DEFAULT_DIETARY_PERCENTAGE.carnivore * peopleCount
    );
    const vegetarianCount = Math.round(
      DEFAULT_DIETARY_PERCENTAGE.vegetarian * peopleCount
    );
    const veganCount = peopleCount - carnivoreCount - vegetarianCount;

    newState = answerQuestion(
      newState,
      {
        id: 'number_of_carnivore',
        value: carnivoreCount,
      },
      { isSideEffect: true }
    );
    newState = answerQuestion(
      newState,
      {
        id: 'number_of_vegans',
        value: veganCount,
      },
      { isSideEffect: true }
    );
    newState = answerQuestion(
      newState,
      {
        id: 'number_of_vegetarians',
        value: vegetarianCount,
      },
      { isSideEffect: true }
    );
  }

  return newState;
}

function handleTotalBudgetChange(
  state: AnsweringQualificationState,
  answer: {
    id: Exclude<QuestionId, 'budget' | 'dietary_restrictions'>;
    value: unknown;
  },
  options: { isManagedAccount?: boolean }
) {
  if (
    answer.id !== totalBudgetQuestion.id ||
    typeof answer.value !== 'number'
  ) {
    return state;
  }

  const pricePerPerson = state.qualification.answers[pricePerPersonQuestion.id];
  const peopleCount = state.qualification.answers[questions.peopleCount.id];

  let newState = state;
  // update price per person
  if (state._firstAnsweredBudgetField === 'peopleCount' && peopleCount) {
    const calculatedPricePerPerson =
      Math.round((answer.value / peopleCount) * 100) / 100;
    if (calculatedPricePerPerson !== pricePerPerson) {
      newState = answerQuestion(
        newState,
        {
          id: pricePerPersonQuestion.id,
          value: calculatedPricePerPerson,
          options: options || {},
        },
        { isSideEffect: true }
      );
    }
  }

  const totalBudget = answer.value;
  // update peopleCount
  if (state._firstAnsweredBudgetField === 'totalBudget') {
    // Update the people count
    if (pricePerPerson && totalBudget) {
      if (!state._isPeopleCountTouched) {
        const newPeopleCount = Math.floor(totalBudget / pricePerPerson);

        newState = answerQuestion(
          newState,
          {
            id: questions.peopleCount.id,
            value: newPeopleCount,
          },
          { isSideEffect: true }
        );

        // Update the price per person
        if (newPeopleCount) {
          newState = answerQuestion(
            newState,
            {
              id: pricePerPersonQuestion.id,
              value: Math.round((totalBudget / newPeopleCount) * 100) / 100,
            },
            { isSideEffect: true }
          );
        }
      }

      if (state._isPeopleCountTouched && peopleCount) {
        newState = answerQuestion(
          newState,
          {
            id: pricePerPersonQuestion.id,
            value: Math.round((totalBudget / peopleCount) * 100) / 100,
          },
          { isSideEffect: true }
        );
      }
    }
  }

  return newState;
}

function handlePricePerPersonChange(
  state: AnsweringQualificationState,
  answer: {
    id: Exclude<QuestionId, 'budget' | 'dietary_restrictions'>;
    value: unknown;
  }
) {
  if (
    answer.id !== pricePerPersonQuestion.id ||
    typeof answer.value !== 'number'
  ) {
    return state;
  }

  const peopleCount = state.qualification.answers.min_order_number;

  // CHANGE: price per person
  if (answer.id === pricePerPersonQuestion.id) {
    if (peopleCount) {
      // update totalBudget
      const sumPrice = answer.value * peopleCount;
      if (sumPrice !== state.qualification.answers.min_order_number) {
        return answerQuestion(state, {
          id: totalBudgetQuestion.id,
          value: Math.floor(sumPrice),
        });
      }
    }
  }

  return state;
}
