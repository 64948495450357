import { EXPERIMENT, Variation } from 'lib/ablyft';
import { Caterer } from 'lib/algolia/model';
import {
  AnsweringQualificationState,
  Answers,
  QUALIFICATION_STATUS,
  QualificationState,
  StepState,
} from 'qualification/context/model';
import { answerInitialQuestions } from 'qualification/context/reducer/answerInitialQuestions';
import { answerQuestion } from 'qualification/context/reducer/answerQuestion';
import {
  disableStep,
  getFirstShownStep,
  getStepIndexAndPercentage,
  isStepValid,
} from 'qualification/context/reducer/utils';
import { Question, Step, StepId } from 'qualification/schema/model';
import { SCHEMA_STEPS, steps } from 'qualification/schema/steps';
import { Bestseller } from 'shared/bestsellers';

export interface QualificationInitializeOptions {
  answers?: Answers;
  disabledSteps?: StepId[];
  selectedCaterer?: Caterer;
  nonSkippableQuestions?: StepId[];
  selectedMenu?: Bestseller;
  leadSource?: string;
  stepOrder?: StepId[];
  variations?: Variation[];
}

export function initialize(
  state: QualificationState,
  {
    answers,
    disabledSteps,
    leadSource,
    nonSkippableQuestions,
    selectedCaterer,
    selectedMenu,
    stepOrder,
    variations,
  }: QualificationInitializeOptions = {}
): AnsweringQualificationState {
  const currentPageHref =
    typeof window === 'undefined'
      ? undefined
      : `${window.location.origin}${window.location.pathname}`;

  leadSource = leadSource || currentPageHref;

  const isUserLoggedIn =
    typeof window !== 'undefined' && window.localStorage.getItem('token');

  // Initialize the questions
  const steps = getInitialSteps({
    isUserLoggedIn: Boolean(isUserLoggedIn),
    variations,
    stepOrder,
  });

  // Define an initial state
  const initialState: AnsweringQualificationState = {
    ...state,
    initialOptions: {
      nonSkippableQuestions,
    },
    status: QUALIFICATION_STATUS.answering,
    qualification: {
      leadSource,
      answers: {},
      errors: {},
      steps: steps.map((q) => ({ id: q.id })),
      step: { id: SCHEMA_STEPS.catering_categories.id },
      stepIndex: 0,
      progressPercentage: 0,
      selectedMenu: selectedMenu,
      selectedCaterer: selectedCaterer,
    },
  };

  // Add initial answers
  let answeredState = answerInitialQuestions(initialState, answers);

  // Handle the selected menu
  if (selectedMenu) {
    answeredState = answerQuestion(answeredState, {
      id: 'catering_categories',
      value: selectedMenu.cateringCategories,
    });
    disableStep(answeredState, 'catering_categories');
  }

  // Order the questions
  const orderedSteps = orderStepsBasedOnAnswers({
    steps: answeredState.qualification.steps,
    answers: answeredState.qualification.answers,
    ignoredAnswers: nonSkippableQuestions,
  });

  const stateWithAnsweredAndOrderedQuestions = {
    ...answeredState,
    qualification: {
      ...answeredState.qualification,
      steps: orderedSteps,
    },
  };

  // Disable answers

  if (disabledSteps?.length) {
    disabledSteps.forEach((stepId) => {
      const hasAnswer = isStepValid(
        stateWithAnsweredAndOrderedQuestions,
        stepId
      );
      if (hasAnswer) {
        disableStep(stateWithAnsweredAndOrderedQuestions, stepId);
      }
    });
  }

  // Determine the first shown question
  const firstShownStep = getFirstShownStep(
    stateWithAnsweredAndOrderedQuestions
  );

  const { stepIndex, progressPercentage } = getStepIndexAndPercentage(
    firstShownStep,
    orderedSteps
  );

  const initializedState: AnsweringQualificationState = {
    ...stateWithAnsweredAndOrderedQuestions,
    status: QUALIFICATION_STATUS.answering,
    qualification: {
      ...stateWithAnsweredAndOrderedQuestions.qualification,
      steps: orderedSteps,
      step: { id: firstShownStep.id },
      progressPercentage,
      stepIndex,
      selectedCaterer,
    },
  };
  return initializedState;
}

function getInitialSteps({
  isUserLoggedIn,
  stepOrder,
  variations,
}: {
  isUserLoggedIn: boolean;
  stepOrder?: StepId[];
  variations?: Variation[];
}) {
  const stepOrderToUse = stepOrder?.map((stepId) => SCHEMA_STEPS[stepId]) || [
    steps.city,
    steps.cateringCategories,
    steps.eventDateAndLeadType,
    steps.budgetAndPeopleCount,
    steps.dietaryRestriction,
  ];

  const filteredSteps = handleConditionalQuestions(stepOrderToUse, {
    isUserLoggedIn,
  });

  if (variations) {
    if (
      variations.includes(EXPERIMENT.customerTierABC.variations.variationB) ||
      variations.includes(EXPERIMENT.customerTierABC.variations.variationC)
    ) {
      filteredSteps.push(steps.customerTier);
    }
  }
  return filteredSteps;
}

function handleConditionalQuestions(
  steps: Step[],
  { isUserLoggedIn }: { isUserLoggedIn: boolean }
) {
  return steps
    .map((step) => {
      const questions = step.questions as Question[];
      const filteredQuestions = questions.filter(
        (question) =>
          !question?.condition || question.condition(Boolean(isUserLoggedIn))
      );
      return { ...step, questions: filteredQuestions };
    })
    .filter((step) => step.questions.length > 0);
}

type OrderOptions = {
  steps: StepState[];
  answers: { [key: string]: unknown };
  ignoredAnswers?: unknown[];
};

function orderStepsBasedOnAnswers({
  steps,
  answers,
  ignoredAnswers,
}: OrderOptions) {
  // Get the keys from the object
  const keys = Object.keys(answers);

  const answeredSteps: typeof steps = [];
  const unansweredSteps: typeof steps = [];

  steps.forEach((step) => {
    const isAnswered = keys.includes(step.id);
    const isIgnored = ignoredAnswers ? ignoredAnswers.includes(step.id) : false;

    if (isIgnored) {
      unansweredSteps.push(step);
      return;
    }
    if (isAnswered) {
      answeredSteps.push(step);
      return;
    }
    unansweredSteps.push(step);
  });

  return [...answeredSteps, ...unansweredSteps];
}
